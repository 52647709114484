// Type imports
import type React from 'react'
import type { ImageProps } from 'next/image'
import type { BaseLocalizedProps } from './types'

// External imports
import Image from '../NextImage'
import styled from '@emotion/styled'

import FullStarIcon from '../../../public/assets/icons/full-star.svg'
import Star81Icon from '../../../public/assets/icons/star-81.svg'

const Container = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
`

const Text = styled.p`
  margin-left: 10px;
  font-weight: 700;
`

const Flex = styled.div`
  display: flex;
`

const ImgContainer = styled.div`
  margin-right: 4px;

`

type Props = BaseLocalizedProps & Omit<ImageProps, 'src' | 'alt' | 'width' | 'height'>

const ChromeStoreRating: React.FC<Props> = ({ t, tOptions, ...props }) => {
  return (
    <Container>
      <Flex>
        <ImgContainer><Image {...props} src={FullStarIcon} alt='full-star' width='16px' height='16px' /></ImgContainer>
        <ImgContainer><Image {...props} src={FullStarIcon} alt='full-star' width='16px' height='16px' /></ImgContainer>
        <ImgContainer><Image {...props} src={FullStarIcon} alt='full-star' width='16px' height='16px' /></ImgContainer>
        <ImgContainer><Image {...props} src={FullStarIcon} alt='full-star' width='16px' height='16px' /></ImgContainer>
        <ImgContainer><Image {...props} src={Star81Icon} alt='star-81' width='16px' height='16px' /></ImgContainer>
      </Flex>
      <Text>{t('CHROME_EXT_STORE', tOptions)}</Text>
    </Container>
  )
}

export default ChromeStoreRating
