import { useState, useEffect } from 'react'

const useWindowWidth = (): number | undefined => {
  const [width, setWindowSize] = useState<number | undefined>()

  useEffect(() => {
    const handleResize = (): void => setWindowSize(window.innerWidth)

    window.addEventListener('resize', handleResize)
    handleResize()

    return (): void => window.removeEventListener('resize', handleResize)
  }, [])

  return width
}

export default useWindowWidth
