// Type imports
import type React from 'react'
import type { Props } from '@outwrite/button'
import type { BaseAction } from '../types'

// External imports
import { forwardRef } from 'react'
import { SecondaryLinkButton } from '@outwrite/button'

// HOCs
import persistQueriesLink from '../hocs/persist-queries-link'

const SecondaryAction = forwardRef<
HTMLButtonElement & HTMLAnchorElement,
BaseAction & Props
>(({ text, ...rest }, ref) => (
  <SecondaryLinkButton {...rest} ref={ref}>{text}</SecondaryLinkButton>
))
SecondaryAction.displayName = 'SecondaryAction'

export default persistQueriesLink(SecondaryAction)
