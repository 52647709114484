// Type imports
import type React from 'react'
import type { NextPage, GetStaticProps } from 'next'
import type { LinkTypes, I18nextKey, ActionTypes } from '../types'
import type { RequiredNs as ComponentNs } from '../components/localized/types'

// External imports
import Image from '../components/NextImage'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { NextSeo } from 'next-seo'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation, Trans } from 'react-i18next'

import styled from '@emotion/styled'

// Others
import useWindowWidth from '../hooks/use-window-width'
import useScrollPassed from '../hooks/use-scrolled-passed'

import Header from '../components/localized/Header'
import Footer from '../components/localized/Footer'
import PlatformLogos from '../components/localized/PlatformLogos'
import ChromeStoreRating from '../components/localized/ChromeStoreRating'
import Testimonials from '../components/localized/Testimonials'

import {
  FlexHeroSection,
  FlexItem as HeroFlexItem,
  Title as HeroTitle,
  Description as HeroDescription
} from '../components/Hero'
import { Section, FlexContainer, Item, FlexItem, Title, Description } from '../components/Section'
import { CenteredSection, Title as CenteredTitle } from '../components/CenteredSection'
import ColoredBackground from '../components/ColoredBackground'

import PrimaryAction from '../components/PrimaryAction'
import SecondaryAction from '../components/SecondaryAction'
import Highlight, { HighlightColor } from '../components/Highlight'
import FeatureGrid from '../components/FeatureGrid'
import InlineLink from '../components/InlineLink'
import BottomCta from '../components/BottomCta'

import {
  defaultPageSpecificSEO,
  MOBILE_WIDTH_PX,
  pageSpecificCtaLink,
  FRANCAIS_PATHNAME,
  ESPANOL_PATHNAME
} from '../constants'

/**
 * Components/Libraries that are dynamically loaded
 */

// Load lottie animation on client only since it such a huge component
const LottieHeroAnimation = dynamic(
  async () => await import('../components/lottie/LottieSpellingGrammar'),
  { ssr: false }
)

/**
 * Load all the namespaces required for this page include all localized components
 */
type RequiredNs = 'home' | 'common' | ComponentNs
const namespaces: Record<RequiredNs, true> = { common: true, home: true }
export const getStaticProps: GetStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale ?? '', Object.keys(namespaces)))
  }
})

/**
 * Constants & Styles
 */

// 50 performance to 87 performance just by hiding this animation in mobile
const MobileHiddenHeroFlexItem = styled(HeroFlexItem)`
  @media screen and (max-width: ${MOBILE_WIDTH_PX}px) {
    display: none;
  }
`

/**
 * Types for structure of page
 */

// did this manually for each key because it was a pain to sort out typescript issues
// but it would be better if we just type it so its just the prefix of the key needed
type HomeKey = I18nextKey<typeof import ('../../public/locales/en/home.json')>
interface FlexSectionContent extends Record<'titleKey' | 'descriptionKey', HomeKey> {
  img: { src: any } & Record<'altKey', HomeKey>
  action?: LinkTypes & Record<'textKey', HomeKey>
  color?: HighlightColor
}

/**
 * Mapping json keys for content structure
 */

const FEATURES: FlexSectionContent[] = [
  {

    titleKey: 'features.grammar.TITLE',
    descriptionKey: 'features.grammar.DESCRIPTION',
    action: { href: '/grammar', textKey: 'features.grammar.ACTION_TEXT' },
    img: {
      src: require('../../public/assets/feature-images/en/grammar-youre.svg'),
      altKey: 'features.grammar.GRAMMAR_YOUR_IMG_ALT'
    },
    color: 'green'
  },
  {
    titleKey: 'features.pro.TITLE',
    descriptionKey: 'features.pro.DESCRIPTION',
    action: { href: '/pro', textKey: 'features.pro.ACTION_TEXT' },
    img: {
      src: require('../../public/assets/feature-images/en/style-various.svg'),
      altKey: 'features.pro.STYLE_VARIOUS_IMG_ALT'
    },
    color: 'purple'
  },
  {
    titleKey: 'features.teams.TITLE',
    descriptionKey: 'features.teams.DESCRIPTION',
    action: { href: '/teams', textKey: 'features.teams.ACTION_TEXT' },
    img: {
      src: require('../../public/assets/feature-images/en/rewrite-formal.svg'),
      altKey: 'features.teams.REWRITE_FORMAL_IMG_ALT'
    },
    color: 'red'
  }
]

/**
 * Page Component
 */

const Home: NextPage = () => {
  const router = useRouter()

  const width = useWindowWidth()

  const { t } = useTranslation('home')
  const { t: tCommon } = useTranslation('common')

  const ctaAction: ActionTypes = { ...t('CTA_BUTTON'), ...pageSpecificCtaLink(router.pathname, router.locale) }

  // Controls for the header to only go sticky after user passes the fold
  const { ref: heroCtaRef, scrolledPassed } = useScrollPassed()

  return (
    <>
      <NextSeo
        {...defaultPageSpecificSEO(router.pathname, router.locale)}
        {...t('SEO')}
      />

      {/* Above the fold */}
      <Header t={tCommon} action={ctaAction} data-sticky={scrolledPassed} bgColor='yellow' dark />
      <ColoredBackground bgColor='yellow'>
        {/* Hero */}
        <FlexHeroSection>
          <HeroFlexItem>
            <HeroTitle>{t('hero.TITLE')}</HeroTitle>
            <HeroDescription>{t('hero.DESCRIPTION')}</HeroDescription>
            <div>
              <PrimaryAction {...ctaAction} ref={heroCtaRef} clickId='hero-cta' />
              <ChromeStoreRating t={tCommon} priority />
            </div>
          </HeroFlexItem>
          <MobileHiddenHeroFlexItem>
            {!!width && width >= MOBILE_WIDTH_PX && (
              // Only load lottie script until it is absolutely necessary
              // Also, the reason why I even added this width hook despite
              // having the media query is so that it doesn't even load at all in mobile
              <LottieHeroAnimation />
            )}
          </MobileHiddenHeroFlexItem>
        </FlexHeroSection>
      </ColoredBackground>

      {/* Platforms */}
      <CenteredSection>
        <CenteredTitle>
          <Trans t={t} i18nKey='platforms.TITLE'><Highlight color='yellow'/></Trans>
        </CenteredTitle>
        <PlatformLogos t={tCommon} platforms={['google-docs', 'word', 'outlook', 'gmail', 'linkedin', 'wordpress']} />
      </CenteredSection>

      {/* Features */}
      <Section bgColor='gray'>
        {FEATURES.map(({ titleKey, descriptionKey, img, action, color }, i) => {
          return (
          <FlexContainer key={titleKey} direction={i % 2 ? 'row-reverse' : 'row'}>
            <Item>
              <Image
                src={img.src}
                alt={t(img.altKey) as string}
                height={345}
                width={483}
              />
            </Item>
            <FlexItem>
              <div>
                <Title>
                  <Trans
                    t={t}
                    i18nKey={titleKey}
                    components={[<Highlight key={i} color={color} />]}
                  />
                </Title>
                <Description>{t(descriptionKey)}</Description>
              </div>
              {action && <SecondaryAction {...action} text={t(action.textKey) as string} />}
            </FlexItem>
          </FlexContainer>
          )
        })}
      </Section>

      {/* Advanced features */}
      <CenteredSection>
        <CenteredTitle>
          <Trans t={t} i18nKey={'advfeatures.TITLE'}><Highlight color='yellow'/></Trans>
        </CenteredTitle>
        <FeatureGrid
          list={[{
            href: '/grammar',
            title: t('advfeatures.grammar.TITLE'),
            description: t('advfeatures.grammar.DESCRIPTION'),
            bgColor: 'yellow',
            icon: { src: require('../../public/assets/icons/check.svg'), alt: '' }
          },
          {
            href: '/readability',
            title: t('advfeatures.readability.TITLE'),
            description: t('advfeatures.readability.DESCRIPTION'),
            bgColor: 'purple',
            icon: { src: require('../../public/assets/icons/chart-bar.svg'), alt: '' }
          },
          {
            title: t('advfeatures.multilingual.TITLE'),
            description: (
              <Trans
                t={t}
                i18nKey={'advfeatures.multilingual.DESCRIPTION'}
                defaults='Check your writing in English, <0>French</0>, and <1>Spanish</1>'
                components={[
                  <InlineLink href={FRANCAIS_PATHNAME} key={0} />,
                  <InlineLink href={ESPANOL_PATHNAME} key={1} />
                ]}
              />
            ),
            bgColor: 'red',
            icon: { src: require('../../public/assets/icons/globe.svg'), alt: '' }
          },
          {
            href: '/pro',
            title: t('advfeatures.style.TITLE'),
            description: t('advfeatures.style.DESCRIPTION'),
            bgColor: 'purple',
            icon: { src: require('../../public/assets/icons/quote.svg'), alt: '' }
          },
          {
            href: '/paraphrasing',
            title: t('advfeatures.paraphrasing.TITLE'),
            description: t('advfeatures.paraphrasing.DESCRIPTION'),
            bgColor: 'red',
            icon: { src: require('../../public/assets/icons/edit.svg'), alt: '' }
          },
          {
            href: '/passive-voice',
            title: t('advfeatures.passiveVoice.TITLE'),
            description: t('advfeatures.passiveVoice.DESCRIPTION'),
            bgColor: 'yellow',
            icon: { src: require('../../public/assets/icons/chat.svg'), alt: '' }
          }]}
        />
      </CenteredSection>

      {/* Testimonials */}
      <Testimonials t={tCommon} />

      {/* BottomCta */}
      <BottomCta action={ctaAction} {...t('BOTTOM_CTA')} />

      {/* Footer */}
      <Footer t={tCommon} />
    </>
  )
}

export default Home
